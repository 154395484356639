
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    priority: Number,
    msg: String,
    title: String
  }
})
export default class Alerts extends Vue {}
