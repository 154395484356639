<template>
  <ViewTemplate title="Test">
    <div class="column">
      <Alerts msg="Ahoj" :priority="1" />
    </div>
  </ViewTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Alerts from "@/components/Alerts.vue";
import ViewTemplate from "@/components/ViewTemplate.vue";

@Options({
  components: {
    Alerts,
    ViewTemplate
  }
})
export default class Test extends Vue {}
</script>

<style scoped>
#dataGrid {
  height: 500px;
}
</style>
