<template>
  <div class="alerts">
    <div v-if="priority == 1 && !msg" class="successGreen">
      <img src="gfx/check.svg" class="big" />{{ title }}
    </div>
    <div v-if="priority == 2 && !msg" class="errorRed">
      <img src="gfx/exclamation.svg" class="big" />{{ title }}
    </div>
    <div v-if="priority == 3 && !msg" class="warningOrange">
      <img src="gfx/warning.svg" class="big" />{{ title }}
    </div>
    <div v-if="priority == 4 && !msg" class="infoBlue">
      <img src="gfx/info-circle-o.svg" class="big" />{{ title }}
    </div>

    <div v-if="priority == 1 && msg" class="green">
      <div class="alertIcon"><img src="gfx/check.svg" /></div>
      <div class="successText">
        <h3>{{ title }}</h3>
        <p>{{ msg }}</p>
      </div>
      <div class="cleaner"></div>
    </div>

    <div v-if="priority == 2 && msg" class="red">
      <div class="alertIcon"><img src="gfx/exclamation.svg" /></div>
      <div class="errorText">
        <h3>{{ title }}</h3>
        <p>{{ msg }}</p>
      </div>
      <div class="cleaner"></div>
    </div>
    <div v-if="priority == 3 && msg" class="orange">
      <div class="alertIcon"><img src="gfx/warning.svg" /></div>
      <div class="warningText">
        <h3>{{ title }}</h3>
        <p>{{ msg }}</p>
      </div>
      <div class="cleaner"></div>
    </div>
    <div v-if="priority == 4 && msg" class="blue">
      <div class="alertIcon"><img src="gfx/info-circle-o.svg" /></div>
      <div class="infoText">
        <h3>{{ title }}</h3>
        <p>{{ msg }}</p>
      </div>
      <div class="cleaner"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    priority: Number,
    msg: String,
    title: String
  }
})
export default class Alerts extends Vue {}
</script>

<style scoped>
.hlavni {
  max-width: 1200px;
  min-height: 900px;
}

.alerts {
  font-size: 14px;
  color: #fff;
}

.big {
  height: 24px;
  vertical-align: middle;
  margin-right: 20px;
}

.successGreen {
  background: #3a8941;
  padding: 20px;
  margin: 10px 0;
}

.warningOrange {
  background: #cf7a33;
  padding: 20px;
  margin: 10px 0;
}

.errorRed {
  background: #ca3c30;
  padding: 20px;
  margin: 10px 0;
}

.infoBlue {
  background: #1c233f;
  padding: 20px;
  margin: 10px 0;
}

/*listAlerts*/

.green {
  background: #3a8941;
  color: #3a8941;
  margin: 10px 0;
}

.red {
  background: #ca3c30;
  color: #ca3c30;
  margin: 10px 0;
}

.orange {
  background: #cf7a33;
  color: #cf7a33;
  margin: 10px 0;
}

.blue {
  background: #1c233f;
  color: #1c233f;
  margin: 10px 0;
}

.alertIcon {
  float: left;
  margin: 15px 0 0 20px;
}

.successText {
  background: #e5f6e8; /*todo*/
  margin-left: 60px;
  padding: 10px;
}

.errorText {
  background: #f9ebed; /*todo*/
  margin-left: 60px;
  padding: 10px;
}

.warningText {
  background: #faf4d7; /*todo*/
  margin-left: 60px;
  padding: 10px;
}

.infoText {
  background: #edf7fe;
  margin-left: 60px;
  padding: 10px;
}

.cleaner {
  clear: both;
  height: 0;
  overflow: hidden;
  content: "";
  display: none;
}

hr {
  height: 0;
  border: 0;
  margin: 5px 0;
  padding: 0;
  clear: both;
}
</style>
